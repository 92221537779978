jQuery(document).ready(function ($) {


    // Track the Click to Call Event inside Analytics
    if(('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch){
        $('[href*="tel:"]').on('click',function(){
            console.log('Mobile Clicked');
            if (typeof gtag_report_conversion === 'function') {
                gtag_report_conversion($(this).prop('href'));
            }

            // Get the event label with fallback if none is set
            if (this.hasAttribute('data-event-label')) {
                var event_label = $(this).data('event-label');
            } else {
                var event_label = 'Body';
            }

            if (typeof gtag === 'function') {
                gtag('event', 'click', {
                    'event_category': 'Click-To-Call: LP',
                    'event_action': 'Mobile Call',
                    'event_label': event_label,
                });
            }
        });
    } else {
        $('[href*="tel:"]').on('click',function(){
            console.log('Desktop Clicked');
            return false;
        });
    }

    $(":input").inputmask();


    // ===== GLOBAL HELPERS
    // ==========================================

    var global_helpers = {
        settings: {
            body: $('body'),
            header: $('header'),
            footer: $('footer'),
            hash: window.location.hash,
            back_to_top: $('.back-to-top'),
            data_anchor_links: $('[data-anchor-link]'),
        },

        init: function () {
            // Scroll to a hash if there is one
            if (this.settings.hash.length) {
                setTimeout(function () {
                    global_helpers.scrollToHash();
                }, 500);
            }
            // Data Anchor Links
            if (this.settings.data_anchor_links.length) {
                global_helpers.dataAnchorLinks();
            }

            this.bindUIActions();
        },

        bindUIActions: function () {
            // Back to top buttons
            global_helpers.settings.back_to_top.on('click', function () {
                $('html, body').animate({
                    scrollTop: 0,
                });
            });

            // Track various events for Analytics
            $('.js-track-event').on('click', function () {
                global_helpers.trackAnalyticsEvents($(this));
                if ($(this).hasClass('input-change')) {
                    $(this).removeClass('js-track-event');
                }
            });
        },

        // Allow smooth scrolling to data-anchor-link items from WYSIWYG content links
        dataAnchorLinks: function () {
            // Loop over everything found, and find the matching link
            global_helpers.settings.data_anchor_links.each(function () {
                $(this).on('click', function () {
                    var link = $(this).attr('data-anchor-link');

                    // Get the target by ID
                    var target = $('#' + link);

                    var header = global_helpers.settings.header.outerHeight();
                    var offset = target.offset().top - header;

                    $('html, body').animate({
                        scrollTop: offset - 45,
                    });

                    return false;
                });
            });
        },

        // Returns a function that, as long as it continues to be invoked, will not
        // be triggered. The function will be called after it stops being called for
        // N milliseconds. If 'immediate' is passed, trigger the function on the
        // leading edge, instead of the trailing.
        //
        // Use:  $(window).resize(global_helpers.debounce(function() { ...[your code here]... }, 500));
        // Source:  http://davidwalsh.name/javascript-debounce-function
        debounce: function (func, wait, immediate) {
            var timeout;
            return function () {
                var context = this,
                    args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) {
                        func.apply(context, args);
                    }
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) {
                    func.apply(context, args);
                }
            };
        },

        // Smooth scroll to the hash content
        scrollToHash: function () {
            // Get the hashed target
            var content = $(global_helpers.settings.hash);

            if (content.length) {
                // Get the current header height if on mobile
                var header = global_helpers.settings.header.outerHeight();
                // Get the offset and add the header height
                var offset = content.offset().top - header - 35;
                // Scroll to the desired content with offset
                $('html, body').animate({
                    scrollTop: offset,
                });
            }
        },

        // Analytics Event Tracking
        trackAnalyticsEvents: function ($elem) {
            if (typeof gtag === 'function') {
                gtag('event', 'click', {
                    'event_category': $elem.data('event-category'),
                    'event_action': $elem.data('event-action'),
                    'event_label': $elem.data('event-label'),
                });
            }
        },

    };
    global_helpers.init();


    // HEADER
    // ===================================================

    var header = {

        settings: {
            header: $('.header'),
            location_dropdown: $('header .has-dropdown .trigger'),
        },

        init: function () {
            header.bindUIActions();
        },

        bindUIActions: function () {
            // On clicking the location dropdown
            this.settings.location_dropdown.on('click', header.toggleLocationDropdown);

            // Show/Hide menus when scrolling up or down
            $(window).on('scroll', function () {
                if ($(window).scrollTop() >= header.settings.header.height() * 3 ) {
                    header.settings.header.addClass('sticky');
                } else {
                    header.settings.header.removeClass('sticky');
                }
            });
        },

        // Location Dropdown
        toggleLocationDropdown: function() {
            if (!header.settings.header.hasClass('locations-open')) {
                header.settings.header.addClass('locations-open');
            } else {
                header.settings.header.removeClass('locations-open');
            }
        },

    };
    header.init();


    // STEPPED FORM
    // ===================================================

    var stepped_form = {
        settings: {
            slider: $('.form-stepped .steps-slider'),
            options: {
                adaptiveHeight: true,
                arrows: false,
                draggable: false,
                fade: true,
                infinite: false,
                speed: 300,
                swipe: false,
                touchMove: false,
            },
            form: $('form.form-stepped'),
            nav_items: $('.form-stepped .steps-nav .step'),
            form_inputs: $('.form-stepped .input-txt, input[type="checkbox"], input[type="radio"]'
            ),
            next_step_button: $('.form-stepped .next-step'),
        },

        init: function () {
            if (stepped_form.settings.slider.length) {
                stepped_form.initializeSteppedForm();
                stepped_form.bindUIActions();
            }
        },

        bindUIActions: function () {
            // On clicking "Next Step" buttons
            this.settings.next_step_button.on('click', function () {
                if (stepped_form.checkRequiredFields()) {
                    stepped_form.goToNextStep();
                }
            });
            // On input focus
            this.settings.form_inputs.on('focus', function () {
                $(this).closest('.error').removeClass('error');
            });
        },

        initializeSteppedForm: function () {
            // Initialize the step slider
            stepped_form.settings.slider.slick(stepped_form.settings.options);
            // On clicking the steps
            stepped_form.settings.nav_items.on('click', function () {
                // Check the current step to make sure it's valid
                if (stepped_form.checkRequiredFields()) {
                    // Get the total number of steps
                    var total = stepped_form.settings.nav_items.length - 1;
                    // Get the current step index
                    var current = stepped_form.settings.slider.slick('slickCurrentSlide');
                    // Get the target index
                    var target = $(this).index();
                    // Get the index of the step immediately after the last valid step
                    var last = $('.form-stepped .valid').last().index();

                    // Only allow going backwards, to an already valid step, or forwards to a step immediately after a valid step
                    if (
                        target < current ||
                        target == current + 1 ||
                        target == last
                    ) {
                        // Deactivate all step classes
                        stepped_form.settings.nav_items.removeClass('active');
                        // Go to that slide
                        stepped_form.settings.slider.slick('slickGoTo', $(this).index());
                        // Activate the current step
                        $(this).addClass('active');
                    }
                }
            });
        },

        // If it's a stepped form, check the required fields before going to the next step
        checkRequiredFields: function () {
            var error = false;

            // Reset the valid class on the current step before checking
            $('.form-stepped .slick-active').removeClass('valid');
            // Get the required fields of the current step
            var required = $('.form-stepped .slick-active .required');
            // First remove all errors
            required.removeClass('error');

            if (required.length) {
                // Loop over all required fields and check that there are values
                required.each(function () {
                    // Get the input values
                    var val = $(this).find('.input-txt').val();

                    // If there are checkboxes make sure at least one is checked
                    if ($(this).find('.checkboxes, .radios').length) {
                        if ($(this).find('.checkboxes input:checked, .radios input:checked').length >= 1
                        ) {
                            val = true;
                        } else {
                            val = false;
                        }
                    }

                    if (!val) {
                        $(this).closest('.required').addClass('error');
                        error = true;
                    }
                });
            }

            if (!error) {
                // Add valid class to current step
                $('.form-stepped .slick-active').addClass('valid');
                $('.form-stepped .step.active').addClass('valid');
                return true;
            } else {
                return false;
            }
        },

        goToNextStep: function () {
            // Go to the next slide
            stepped_form.settings.slider.slick('slickNext');
            // Deactivate all step classes
            stepped_form.settings.nav_items.removeClass('active');
            // Get the current slide index
            var index = stepped_form.settings.slider.slick('slickCurrentSlide');
            $('.form-stepped .steps-nav .step:eq(' + index + ')').addClass('active');
        },
    };
    stepped_form.init();


    // CONTACT FORM CONTROLS
    // ===================================================

    var form_controls = {
        settings: {
            required: $('form'),
            uniform_elements: 'select, input[type="checkbox"], input[type="radio"]',
            form_inputs: $('form .input-txt, form select'),
        },

        init: function () {
            if (this.settings.required.length) {
                form_controls.bindUIActions();
                form_controls.uniformInit();
                form_controls.initializeLabels();
            }
        },

        bindUIActions: function () {
            // Activate rows on click
            this.settings.form_inputs.on('blur focus', form_controls.toggleLabels);
        },

        // Applies uniform styling to form elements (i.e. <select>, etc.)
        uniformInit: function () {
            $(this.settings.uniform_elements).uniform({
                selectAutoWidth: false,
            });
        },

        // On page load activate labels that have a value in them
        initializeLabels: function () {
            this.settings.form_inputs.each(function () {
				if ($(this).val() !== null && $(this).val().length) {
                    $(this).closest('.form-row').addClass('active');
                }
            });
        },

        // Toggle label positioning
        toggleLabels: function (e) {
            // Check for empty inputs and deactivate labels on blur
            if (e.type == 'blur') {
                // If it's empty and not a date field
				if ($(this).val() !== null && !$(this).val().length) {
                    $(this).closest('.form-row').removeClass('active');
                }
            } else {
                $(this).closest('.form-row').addClass('active');

                // If it's a textarea
                if ($(this).is('textarea')) {
                    $(this).addClass('open');
                }
            }
        },
    };
    form_controls.init();


	// RECAPTCHA HANDLER
    // ===================================================

	var recaptchaHandler = {
		settings: {
			recaptchaKey: TRIMARK_RECAPTCHA.site_key || '',
			recaptchaTokenCollected: false
		},

		init: function () {
			this.bindUIActions();
			this.initRecaptchaPageView();
		},

		bindUIActions: function () {
			$('form').on('submit', this.submit);
		},

		// Handle form submission requests
		submit: function (e) {
			if (!recaptchaHandler.settings.recaptchaTokenCollected) {
				$(this).find('button[type="submit"]').prop('disabled', true);
				recaptchaHandler.initRecaptchaFormSubmit($(this));
				return false;
			}
		},

		// Trigger reCAPTCHA for page views
		initRecaptchaPageView: function () {
			grecaptcha.ready(function () {
				grecaptcha.execute(recaptchaHandler.settings.recaptchaKey, { action: 'pageView' });
			});
		},

		// Trigger reCAPTCHA for form submissions
		initRecaptchaFormSubmit: function (form) {
			grecaptcha.ready(function () {
				grecaptcha.execute(recaptchaHandler.settings.recaptchaKey, { action: 'formSubmit' }).then(function (token) {
					form.find('input[name="g-recaptcha-response"]').val(token);
					recaptchaHandler.settings.recaptchaTokenCollected = true;
					form.submit();
				});
			});
		}
	};
	recaptchaHandler.init();


    // ACF BLOCKS
    // ===================================================

    var acf_blocks = {

        settings: {
            energy_calculator: $('.block-lp-energy-calculator'),
            image_gallery: $('.block-image-gallery'),
            image_gallery_slider: $('.block-lp-image-gallery .slider'),
            product_slider: $('.block-lp-image-slider'),
            testimonial_slider: $('.online-reviews'),
            video_gallery: $('.block-video-gallery'),
        },

        init: function () {
            // Energy Savings Calculator
            if (this.settings.energy_calculator.length) {
                this.rangeSlider();
            }
            // Image Gallery
            if (this.settings.image_gallery.length) {
                this.imageGallery();
            }
            // Image Gallery - Tabbed Slider
            if (this.settings.image_gallery_slider.length) {
                this.imageGallerySlider();
            }
            // Product Slider
            if (this.settings.product_slider.length) {
                this.productSlider();
            }
            // Testimonials Slider
            if (this.settings.testimonial_slider.length) {
                this.testimonialSlider();
            }
            // Video Gallery
            if (this.settings.video_gallery.length) {
                this.videoGallery();
            }
        },

        // Image Gallery
        imageGallery: function () {
            // If there is a view more button
            var view_more = $('.block-lp-image-gallery .view-more');

            if (view_more.length) {
                view_more.on('click', function () {
                    // Remove hidden class from all blocks in that gallery
                    $(this)
                        .closest('.block-lp-image-gallery')
                        .find('.block')
                        .removeClass('hidden hidden-mobile');
                    // If this is a slider then reset the container height
                    var height = $(this)
                        .closest('.block-lp-image-gallery')
                        .find('.slick-active')
                        .outerHeight();
                    $(this)
                        .closest('.block-lp-image-gallery')
                        .find('.image-grid .slick-list')
                        .css('height', height + 'px');
                    // Then remove the button
                    $(this).remove();
                });
            }
        },

        // Image Gallery - Tabbed Slider
        imageGallerySlider: function () {
            acf_blocks.settings.image_gallery_slider.each(function () {
                // Product Slider
                $(this).slick({
                    adaptiveHeight: true,
                    arrows: false,
                    draggable: false,
                    fade: true,
                    infinite: false,
                    slidesToShow: 1,
                    speed: 400,
                    swipe: false,
                    touchMove: false,
                });

                // Tab Controls
                var tabs = $(this)
                    .closest('.block-lp-image-gallery')
                    .find('.slider-tabs .tab');

                // Bind event listeners
                tabs.on('click', function () {
                    // Activate Tab
                    tabs.removeClass('active');
                    $(this).addClass('active');

                    var index = $(this).index();

                    // Advance Slider
                    $(this)
                        .closest('.block-lp-image-gallery')
                        .find('.slider')
                        .slick('slickGoTo', index);
                });

            });
        },

        // Product Slider
        productSlider: function () {
            acf_blocks.settings.product_slider.each(function () {
                $(this)
                    .find('.image-slider')
                    .slick({
                        appendArrows: $(this).find('.controls'),
                        appendDots: $(this).find('.controls'),
                        asNavFor: $(this).find('.text-slider'),
                        centerMode: true,
                        centerPadding: '45px',
                        dots: true,
                        dotsClass: 'slick-dots-circles',
                        mobileFirst: true,
                        nextArrow:
                            '<div class="next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.09 32"><polygon points="0 27.91 4.09 32 20.09 16 16 11.91 4.09 0 0 4.09 11.91 16 0 27.91"/></svg></div>',
                        prevArrow:
                            '<div class="prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.09 32"><polygon points="0 27.91 4.09 32 20.09 16 16 11.91 4.09 0 0 4.09 11.91 16 0 27.91"/></svg></div>',
                        slidesToShow: 1,
                        speed: 400,
                        touchThreshold: 25,
                        responsive: [
                            {
                                breakpoint: 499,
                                settings: {
                                    centerPadding: '110px',
                                },
                            },
                        ],
                    });

                $(this).find('.text-slider').slick({
                    arrows: false,
                    draggable: false,
                    fade: true,
                    swipe: false,
                    touchMove: false,
                });

                // If a product topic has been set, ie ?t=b, then switch to that slide on load
                if (typeof product_topic !== 'undefined' && product_topic != 'Replacement') {
                    var slide = $('.image-slider .slick-slide').not('.slick-cloned').find('[data-product-slide="'+product_topic+'"]').first().closest('.slick-slide').data('slick-index');
                    $(this).find('.image-slider').slick('slickGoTo', slide);
                }

            });
        },

        rangeSlider: function () {
            acf_blocks.settings.energy_calculator.each(function () {
                // Range Slider
                if ($(this).find('.range-slider').length) {
                    var range = $(this).find('.range-slider');

                    // Set initial index
                    switchValues(0);

                    // On slider change
                    range.find('input').on('change input', function () {
                        switchValues($(this).val());
                        followSliderButton($(this).val());
                    });

                    // Activate values for both areas
                    function switchValues(index) {
                        // Remove all values first
                        range.find('.number').removeClass('active');
                        // Slider Display
                        range
                            .find('.slider-display .number')
                            .eq(index)
                            .addClass('active');
                        // Range Display
                        range
                            .find('.range-display .number')
                            .eq(index)
                            .addClass('active');
                    }

                    // Move the text to follow the range slider button
                    function followSliderButton(index) {
                        // Move things a little differently on mobile/larger screens to avoid overlap
                        if ($(window).width() < 640) {
                            var offset = 6;
                        } else {
                            var offset = 1.75;
                        }

                        // Get the number of values and compute %
                        var percentage = 100 / range.find('.range-input').attr('max') - offset;
                        var position = index * percentage;

                        // Move the text
                        range.find('.slider-display').css('left', position + '%');
                    }
                }
            });
        },

        testimonialSlider: function () {
            // Initialize the Online Reviews Slider
            acf_blocks.settings.testimonial_slider.each(function () {
                $(this)
                    .find('.slider')
                    .slick({
                        appendArrows: $(this).find('.controls'),
                        appendDots: $(this).find('.controls'),
                        cssEase: 'ease-in-out',
                        dots: true,
                        dotsClass: 'slick-dots-circles',
                        nextArrow:
                            '<div class="next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.09 32"><polygon points="0 27.91 4.09 32 20.09 16 16 11.91 4.09 0 0 4.09 11.91 16 0 27.91"/></svg></div>',
                        prevArrow:
                            '<div class="prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.09 32"><polygon points="0 27.91 4.09 32 20.09 16 16 11.91 4.09 0 0 4.09 11.91 16 0 27.91"/></svg></div>',
                        slidesToShow: 1,
                        speed: 500,
                        touchThreshold: 25,
                    });
            });
        },

        // Video Gallery
        videoGallery: function () {
            // If there is a view more button
            var view_more = $('.block-video-gallery .view-more');

            if (view_more.length) {
                view_more.on('click', function () {
                    // Remove hidden class from all blocks in that gallery
                    $(this)
                        .closest('.block-video-gallery')
                        .find('.hidden')
                        .removeClass('hidden');
                    // Then remove the button
                    $(this).remove();
                });
            }
        },
    };
    acf_blocks.init();


    // ===== MISCELLANEOUS ITEMS
    // ==========================================

    // var misc = {

    //     settings: {
    //         exit_popup: $('.exit-popup-overlay'),
    //     },

    //     init: function () {
    //         // Exit Popup
    //         if (this.settings.exit_popup.length) {
    //             this.exitPopup();
    //         }
    //     },

    //     // Exit Popup
    //     exitPopup: function() {

    //         // Check the sessionStorage
    //         if (sessionStorage.getItem('exit_popup') != 'closed') {

    //             // Activate exit popup when mouse leaves the window
    //             $('html').on('mouseleave', function(e) {
    //                 if (e.clientY <= 0) {
    //                     $('body').addClass('no-scroll');
    //                     $('.exit-popup-overlay').addClass('active');
    //                     // Turn off event listener
    //                     $(this).off('mouseleave');
    //                 }
    //             });

    //             // Close the popup on clicking a few items
    //             $('.exit-popup .close, .exit-popup a').on('click', function() {
    //                 $('body').removeClass('no-scroll');
    //                 $('.exit-popup-overlay').remove();
    //                 // Set a variable to prevent from opening again this session
    //                 sessionStorage.setItem('exit_popup', 'closed');
    //             });

    //         }
    //     },

    // }
    // misc.init();

	/**
	 * Video play toggle button
	 */
	$('.btn-toggle-playback').on('click', function() {
		var $this = $(this);
		var $text = $this.find('.js-text');
		var $video = $('#' + $this.data('video'));

		if ($this.data('playing') === true) {
			$this.data('playing', false);
			$this.addClass('paused')
			$text.text('Play');
			$video[0].pause();
		} else {
			$this.data('playing', true);
			$this.removeClass('paused')
			$text.text('Pause');
			$video[0].play();
		}
	});

	/**
	 * Pause video when browser tab/window is not active.
	 */
	var $heroVideo = $('.block-lp-hero video');
	var heroVideo = $heroVideo.length ? $heroVideo[0] : null;

	// Handle page visibility change:
	// - If the page is hidden, pause the video
	// - If the page is shown, play the video
	document.addEventListener("visibilitychange", () => {
		if (heroVideo) {
			if (document.hidden) {
				heroVideo.pause();
			} else {
				heroVideo.play();
			}
		}
	});

});
